.notification-icon {
  width: 20px;
  object-fit: cover;
}

.logo {
  font-weight: 800;
  color: white;
  font-size: 1.7rem;
  letter-spacing: 0.2em;
}

.brand-logo {
  padding: 1px 1px;
  background-color: whitesmoke;
  border-radius: 15px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.form-checkbox {
  display: flex;
  align-items: center;
  label {
    margin: 0 10px 0px 0;
    font-size: 1.2rem;
    text-align: center;
  }
}
.login-msg {
  line-height: 3rem;
}
.settings {
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border-radius: 0.42rem;
  background: white;
  .col {
    padding: 20px 0px;
    .row {
      display: flex;
      justify-content: space-around;
      padding: 20px;
      margin: 10px;

      .setting-card {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        height: 70px;
        padding: 10px 20px;
        // background-color: #f7f7f7;
        border-radius: 0.22rem;
        a {
          color: black;
          .setting-child {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            svg {
              font-size: 3.8rem;
              margin-right: 10px;
              background-color: #f7f7f7;
              color: #1e1e2d;
              padding: 10px;
              // width: 40px;
              // height: 40px;
              text-align: center;
              border-radius: 5px;
            }
          }
          h5 {
            margin: 0;
            color: #3699ff;
          }
          p {
            color: gray;
            margin: 0;
          }
        }

        cursor: pointer;
      }
    }
  }
}

.setting-card:hover {
  // color: lightcoral;
  background-color: #f7f7f7;
  border-radius: 0.42rem;
}

.cat-wrapper {
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border-radius: 0.42rem;
  .col-4 {
    background-color: white;
    // height: 90vh;
    .cat-header {
      padding: 30px 0px 0 0;
      h3 {
        font-weight: 500;
        font-size: 1.275rem;
        color: #212121;
      }
    }
    .cat-form {
      form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
      }
    }
  }
  .col-6 {
    background-color: lightcoral;
    height: 90vh;
  }
}
