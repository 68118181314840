//
// Base
//

// Body
body {
  background: $page-bg;
}

html,
body {
  // font-size: 95% !important;
}
// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      padding-top: get($header-config, desktop, fixed, height) +
        get($subheader-config, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize & {
      padding-left: get($aside-config, base, minimized-width);
    }
    .react-bootstrap-table.table-responsive {
      margin-bottom: 1.5rem !important;
    }
  }
  .order {
    .card-header {
      display: flex !important;
      justify-content: space-between !important;
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }

  // ---------------------------------------------------------
  .csv {
    .react-daterange-picker__wrapper {
      min-width: 20rem !important;
    }
  }
  .react-date-picker__wrapper {
    padding: 5px 5px 5px 5px !important;
    border-radius: 5px !important;
    border: 1px solid #ebebeb !important;
  }

  .react-daterange-picker__wrapper {
    padding: 5px 5px 5px 5px !important;
    border-radius: 5px !important;
    border: 1px solid #ebebeb !important;
  }

  .shipment {
    .react-date-picker__wrapper {
      border-bottom: 1.5px solid #757575 !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-radius: 0 !important;
      min-width: 410px;
    }
  }
  .otz {
    .react-daterange-picker__wrapper {
      min-width: 20rem !important;
    }
  }

  .col-8 {
    .card.card-custom.gutter-b {
      margin: 0 !important;
      height: 100% !important;
    }
  }
  .cat-wrapper.row {
    height: 100%;
  }
  .editIconWrapper {
    background-color: #f3f6f9;
    display: flex;
    justify-content: center;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }
  .editIconWrapper:hover {
    background-color: #3699ff;
    color: white;
    z-index: 2;
  }
  .editIcon {
    color: #3699ff;
  }

  .resource-creation :hover {
    // text-decoration: underline !important;
    // color: #3699ff;
  }

  // Accordion
  // .Collapsible {
  //   background-color: red;
  // }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }
    .card-header.order {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }

  .order {
    .card-header {
      display: flex !important;
      justify-content: center !important;
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
  .react-bootstrap-table.table-responsive {
    margin-bottom: 1.5rem !important;
  }
  .title {
    .card-label {
      display: none !important;
    }
  }

  // Dates
  .react-date-picker__wrapper {
    padding: 5px 5px 5px 5px !important;
    border-radius: 5px !important;
    border: 1px solid #ebebeb !important;
  }

  .react-daterange-picker__wrapper {
    padding: 5px 5px 5px 5px !important;
    border-radius: 5px !important;
    border: 1px solid #ebebeb !important;
  }

  .shipment {
    .react-date-picker__wrapper {
      border-bottom: 1.5px solid #757575 !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-radius: 0 !important;
      min-width: 410px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .title {
    .card-label {
      display: none !important;
    }
  }
}

// html,
// body {
//   @media only screen and (max-width: 1000px) {
//     font-size: 56% !important;
//   }
//   @media only screen and (max-width: 750px) {
//     font-size: 50% !important;
//   }
//   @media only screen and (max-width: 700px) {
//     font-size: 45% !important;
//   }
//   @media only screen and (max-width: 500px) {
//     font-size: 40% !important;
//   }
//   @media only screen and (max-width: 400px) {
//     font-size: 36% !important;
//   }
// }
